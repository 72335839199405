import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import Analytics from '../../../analytics/Analytics';
import ContentPopups from '../../../components/ad/ContentPopups';
import HelperNavigation from '../../../components/common/HelperNavigation';
import SharePopover from '../../../components/common/SharePopover';
import SidebarLayout from '../../../components/layout/SidebarLayout';
import ProductPageContent from '../../../components/product/ProductPageContent';
import DefaultSidebar from '../../../components/sidebar/DefaultSidebar';
import SidebarSkeleton from '../../../components/skeleton/SidebarSkeleton';
import useImageUrl from '../../../hooks/image/useImageUrl';
import FullProduct from '../../../models/product/FullProduct';
import { modelOf } from '../../../prop-types';
import { LocationContextPropType } from '../../../services/LocationContext';
import RouteService from '../../../services/RouteService';
import AccountStore from '../../../store/AccountStore';
import AdStore from '../../../store/AdStore';
import CategoryStore from '../../../store/CategoryStore';
import ConfigStore from '../../../store/ConfigStore';
import SectionStore from '../../../store/SectionStore';
import TrackingStore from '../../../store/TrackingStore';
import UIStore from '../../../store/UIStore';
import ProductTypeClass from '../../../types/ProductTypeClass';
import RequestState from '../../../types/RequestState';
import TrackingEventType from '../../../types/TrackingPageType';
import useProductPageContainer from './hook';
import { parse } from 'query-string';

const ProductPageContainer = ({
  accountStore,
  adStore,
  categoryStore,
  configStore,
  sectionStore,
  trackingStore,
  uiStore,
  analytics,
  routeService,
  locationContext,
  setActiveProductId,
  product,
  activeProductId,
}) => {
  const [updateActiveCategory] = useProductPageContainer({
    categoryStore,
    sectionStore,
    product,
  });
  const media = useImageUrl({ product, locationContext });

  const location = useLocation();
  useEffect(() => {
    updateActiveCategory();
    loadBanners();
  }, []);

  useEffect(() => {
    loadSizeGuides();
    configStore.integrations.spotmore.enabled && sendTrackingData();
    configStore.analytics.ga4.enabled && sendAnalyticsEvents();
  }, [activeProductId]);

  const loadSizeGuides = () => {
    const activeProduct = product.getActualProduct(activeProductId) || product;

    if (
      activeProduct?.product_type === ProductTypeClass.GIFT_VOUCHER ||
      activeProduct?.sizeGuidesState !== RequestState.NONE
    ) {
      return;
    }

    const sizeGuidesParams = getCommonParams();

    activeProduct.loadSizeGuides(sizeGuidesParams).catch((e) => {
      if (e.response && e.response.status !== 404) {
        console.error(e);
      }
    });
  };

  const loadBanners = () => {
    const params = getAdSearchParams();

    if (shouldHaveAds()) {
      adStore.loadAds(params, '/product').catch((e) => {
        console.error(e);
      });
    }
  };

  const getAdSearchParams = () => {
    const commonAdParams = getCommonParams();

    commonAdParams.product = product.id;

    if (product.all_category_ids.length > 0) {
      commonAdParams.allCategories = product.all_category_ids;
    }

    if (product.manufacturer_id) {
      commonAdParams.manufacturer = product.manufacturer_id;
    }

    return commonAdParams;
  };

  const getCommonParams = () => {
    const commonParams = {};

    if (sectionStore.activeSection) {
      commonParams.section = sectionStore.activeSection.id;
    }

    if (categoryStore.activeCategory) {
      commonParams.category = categoryStore.activeCategory.id;
    }

    return commonParams;
  };

  const shouldHaveAds = () => {
    return adStore.productPageAds.length > 0;
  };

  const shouldHaveSidebarAds = () => {
    return adStore.productPageAds.indexOf('SIDEBAR_BANNER') !== -1;
  };

  const shouldHavePopup = () => {
    return adStore.productPageAds.indexOf('POPUP_CONTENT') !== -1;
  };

  const sendTrackingData = () => {
    const actualProduct = product.getActualProduct(activeProductId) || product;

    if (typeof document === 'undefined') {
      return null;
    }

    if (configStore.integrations.spotmore.enabled && window.crmtracker) {
      window.crmtracker.sendData();
    }

    trackingStore.setPageView({
      categoryId: categoryStore.activeCategory
        ? categoryStore.activeCategory.id
        : null,
      categoryName: categoryStore.activeCategory
        ? categoryStore.activeCategory.name
        : null,
      page: TrackingEventType.PRODUCT,
      parentCategoryId: categoryStore.activeCategory
        ? categoryStore.activeCategory.parent_id
        : null,
      productActive: typeof actualProduct !== 'undefined',
      productId: actualProduct.id,
      productName: actualProduct.name,
      refererUrl: document.referrer,
      visitedUrl: window.location.href,
    });

    trackingStore.postPageView();
  };

  const sendAnalyticsEvents = () => {
    const actualProduct = product.getActualProduct(activeProductId) || product;
    // Fallback to main product id, mainly for collection product.
    const activeIdForAnalytics = activeProductId || actualProduct?.id || null;

    if (typeof document === 'undefined' || !actualProduct || !activeIdForAnalytics) {
      return null;
    }

    const analyticsProduct = {
      product: actualProduct,
      activeProductId: activeIdForAnalytics,
    };

    analytics.productDetail({
      productList: [analyticsProduct],
      value: actualProduct.getPriceWithPrecision(
        accountStore.showPricesWithTax,
        activeIdForAnalytics
      ),
    });
  };

  const renderShareButtons = () => {
    return <SharePopover media={media} productId={product.id} />;
  };

  const breadcrumbs = product
    ? routeService.transformBreadCrumbs(
        product.breadcrumbsForCategory(categoryStore.activeCategory)
      )
    : [];

  const adSearchParams = getAdSearchParams();
  const shareButtons = product && renderShareButtons();
  const queryParams = parse(location.search);

  return (
    <Container className="ProductPage__container">
      <SidebarLayout
        sidebarPlaceHolder={!uiStore.isMobile ? <SidebarSkeleton /> : null}
        sidebar={
          !uiStore.isMobile &&
          product && (
            <DefaultSidebar
              bannerSearchParams={
                shouldHaveSidebarAds() ? adSearchParams : null
              }
            />
          )
        }
        content={
          <>
            {!uiStore.isMobile && (
              <HelperNavigation
                breadcrumbsPath={breadcrumbs}
                withGoBackLink
                rightSide={shareButtons}
              />
            )}
            <ProductPageContent
              shareButtons={shareButtons}
              product={product}
              activeProductId={activeProductId}
              setActiveProductId={setActiveProductId}
              adSearchParams={adSearchParams}
              queryParams={queryParams}
            />
            {shouldHavePopup() && (
              <ContentPopups searchParams={adSearchParams} />
            )}
          </>
        }
      />
    </Container>
  );
};

ProductPageContainer.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  adStore: modelOf(AdStore).isRequired,
  categoryStore: modelOf(CategoryStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  trackingStore: modelOf(TrackingStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  locationContext: LocationContextPropType.isRequired,
  setActiveProductId: PropTypes.func.isRequired,
  product: modelOf(FullProduct).isRequired,
  activeProductId: PropTypes.string,
};

export default inject(
  'accountStore',
  'adStore',
  'categoryStore',
  'configStore',
  'sectionStore',
  'trackingStore',
  'uiStore',
  'analytics',
  'routeService',
  'locationContext'
)(observer(ProductPageContainer));
