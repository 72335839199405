import ProductClass from '../../../types/ProductClass';
import ProductTypeClass from '../../../types/ProductTypeClass';
import { roundWithPrecision } from '../../../util/number';

/**
 * @typedef Item
 * @type {Object}
 * @property {string} item_id
 * @property {string} item_name
 * @property {string} coupon
 * @property {string} currency
 * @property {number} discount
 * @property {number} index
 * @property {string} item_brand
 * @property {string} item_category
 * @property {string} item_category2
 * @property {string} item_category3
 * @property {string} item_category4
 * @property {string} item_category5
 * @property {string} item_list_id
 * @property {string} item_list_name
 * @property {string} item_variant
 * @property {number} price
 * @property {string} quantity
 */

/**
 * @typedef AnalyticsItem
 * @type {Object}
 * @property {Product} product
 * @property {number} quantity
 * @property {number} position
 * @property {number|string} price
 * @property {number} newQuantity
 * @property {string} activeProductId
 * @property {string} itemListId
 * @property {string} itemListName
 * @property {string} creativeName
 * @property {string} creativeSlot
 */

class Item {
  /**
   * Private fields
   */
  #accountStore;
  #campaignCodeStore;
  #configStore;
  #currencyStore;
  #sectionStore;
  #withTax;

  /**
   *
   * @param {AccountStore} accountStore
   * @param {CampaignCodeStore} campaignCodeStore
   * @param {ConfigStore} configStore
   * @param {CurrencyStore} currencyStore
   * @param {SectionStore} sectionStore
   */
  constructor(
    accountStore,
    campaignCodeStore,
    configStore,
    currencyStore,
    sectionStore
  ) {
    this.#accountStore = accountStore;
    this.#campaignCodeStore = campaignCodeStore;
    this.#configStore = configStore;
    this.#currencyStore = currencyStore;
    this.#sectionStore = sectionStore;

    this.#withTax = accountStore.showPricesWithTax;
  }

  /**
   * @param {AnalyticsItem} analyticsItem
   * @param {boolean} knownProduct
   * @returns {Object.<Item>}
   */
  analyticsItemToItem = (
    {
      product,
      quantity,
      discount,
      position = undefined,
      newQuantity,
      activeProductId,
      itemListId = undefined,
      itemListName = undefined,
      affiliation = undefined,
    },
    knownProduct = false
  ) => {
    let item = {};

    if (!knownProduct) {
      if (product.class === ProductClass.MULTI && activeProductId) {
        product = product.multi.findChild(activeProductId) || product;
      }

      item = this.#createItem(product, activeProductId);
    }

    const addItemBaseProperties = () => {
      item.item_id = product.id || undefined;

      // Product is known when it has been returned from the backend.
      const productName =
        this.#getProductName(knownProduct, product) || undefined;
      item.item_name = productName;

      const brand =
        product.manufacturer?.name || product.manufacturers_name || undefined;
      item.item_brand = brand;

      /**
       * Product may have newQuantity or quantity field.
       * Quantity can also be 1 by default.
       * If quantity is omitted Google analytics defaults to 1.
       */
      const realQuantity = newQuantity || quantity || 1;
      item.quantity = realQuantity;

      if (product.price_info || product.class === ProductTypeClass.DISCOUNT) {
        item.price = this.#getPrice(product, realQuantity);
      }

      item.affiliation = affiliation;

      const categories = product.canonicalPath
        ? this.#getItemCategories(product.canonicalPath)
        : [];
      item = {
        ...item,
        ...categories,
      };

      // Product is known when it has been returned from the backend.
      if (knownProduct) {
        item.item_variant =
          product.variations?.length > 0
            ? product.variations.join(',')
            : undefined;
      }
    };

    const addItemDiscountProperties = () => {
      const activeCoupon = this.#campaignCodeStore.activeCoupon;
      if (activeCoupon && activeCoupon.isLimitedToProduct(product.id)) {
        item.coupon = activeCoupon.code;
      }

      /**
       * TODO: Cart api doesn't return price_info model per product.
       */
      if (product.discount) {
        item.discount = product.discount;
      }

      /**
       * TODO: This should get removed once wishlist has been reworked
       */
      if (discount) {
        item.discount = discount;
      }

      /**
       * TODO: This should be the preferred way to get product discount
       */
      if (product.price_info?.is_discount) {
        item.discount = this.#getDiscount(product);
      }
    };

    const addItemCartProperties = () => {
      if (product?.cartPrice) {
        item.price = product.cartPrice;
      }
    };

    const addItemListProperties = () => {
      item.item_list_id = itemListId;
      item.item_list_name = itemListName;
      item.index = position;
    };

    const addItemPromotionProperties = () => {
      if (product?.creativeName) {
        item.creative_name = product.creativeName;
      }

      if (product?.creativeSlot) {
        item.creative_slot = product.creativeSlot;
      }
    };

    addItemBaseProperties();

    addItemDiscountProperties();

    addItemCartProperties();

    addItemListProperties();

    addItemPromotionProperties();

    return item;
  };

  /**
   * @param {Object} product
   * @param {string} activeProductId
   * @returns {Object} product
   */
  #createItem = (product, activeProductId) => {
    let item = {};

    if (product.class === ProductClass.COLLECTION && activeProductId) {
      item.item_variant = this.#formatCollectionVariant(
        product.collection,
        activeProductId
      );
    }

    /**
     * TODO: Remove product.hierarchy when wishlist has been reworked.
     *
     * Wishlist product has a special model that has been manually built
     * and we cannot create Product-model with the data.
     * If computed property uses root store data eg. product.mainCategory,
     * root is replaced when a new model is created.
     */
    const hierarchy = product.mainCategory?.hierarchy || product.hierarchy;
    const mainItemCategory = this.#getMainItemCategory(product, hierarchy);
    if (mainItemCategory) {
      const ifSectionsActive = this.#configStore.activateSections;
      const startingCategoryNumber = ifSectionsActive ? 0 : 1;
      const additiveNumber = ifSectionsActive ? 2 : 1;
      let categories = {};

      for (
        let index = startingCategoryNumber;
        index < hierarchy.length;
        index++
      ) {
        const category = hierarchy[index];

        // Google item categories start at 2 after main category
        categories = {
          ...categories,
          [`item_category${index + additiveNumber}`]: category.name,
        };
      }

      categories = {
        ...categories,
        item_category: mainItemCategory,
      };

      item = {
        ...item,
        ...categories,
      };
    }
    
    return item;
  };

  #formatCollectionVariant = (collection, activeProductId) => {
    const item = collection.getItemWithProductId(activeProductId);
    if (!item) {
      return undefined;
    }

    const columnElement = collection.column.getElementWithId(item.column_id);
    const columnElementFormatted = this.#formatPropertyElement(columnElement);
    const rowElement = collection.row.getElementWithId(item.row_id);
    const rowElementFormatted = this.#formatPropertyElement(rowElement);
    const collectionVariantString = `${collection.column.name}: ${columnElementFormatted},${collection.row.name}: ${rowElementFormatted}`;
    return this.#cleanItemVariant(collectionVariantString);
  };

  #formatPropertyElement = (element) => {
    return element.name;
  };

  #cleanItemVariant = (text) => {
    // Regular expression to remove &nbsp;, trailing commas, colons, and whitespace
    return text
      .replace(/&nbsp;/g, '')
      .replace(/[\s,:]+$/g, '')
      .trim();
  };

  /**
   * @param {Product} product
   * @param {Array.<Category>} hierarchy
   * @returns {string} category name
   */
  #getMainItemCategory = (product, hierarchy) => {
    const ifSectionsActive = this.#configStore.activateSections;

    if (!hierarchy) {
      return;
    }

    if (ifSectionsActive && product.main_section_id) {
      return this.#sectionStore.findSectionById(product.main_section_id)
        ?.display_name;
    }

    return hierarchy[0]?.name;
  };

  /**
   *
   * @param {boolean} knownProduct
   * @param {Product} product
   * @returns
   */
  #getProductName = (knownProduct, product) => {
    const getFullProductName = () => {
      if (product.model) {
        return product.name.concat(' ', product.model);
      }

      return product.name;
    };

    return knownProduct
      ? getFullProductName()
      : product.multiproduct_title || getFullProductName();
  };

  /**
   * @param {Product} product
   * @param {number} quantity
   * @returns {number} price
   */
  #getPrice = (product, quantity) => {
    if (product.type === ProductTypeClass.DISCOUNT) {
      return product.final_unit_price;
    }

    return this.#calculatePrice(product, quantity);
  };

  /**
   * @param {Product} product
   * @returns {number} price
   */
  #calculatePrice = (product) => {
    const price = product.price_info.getPrice(this.#withTax, product.id);
    const quantityStep = product.package_size
      ? product.getQuantityStep(product)
      : 1;
    const sum = price * quantityStep;
    return Number(sum.toFixed(4));
  };

  /**
   *
   * @param {Array.<string>} canonicalPath
   * @returns {Array}
   */
  #getItemCategories = (canonicalPath) => {
    let categories = {};

    for (let i = 0; i < canonicalPath.length; i++) {
      // Google item categories start at 2 after main category
      const startingCategoryIndex = i >= 1 ? 1 + i : '';
      categories = {
        ...categories,
        [`item_category${startingCategoryIndex}`]: canonicalPath[i],
      };
    }

    return categories;
  };

  /**
   * @param {Product} product
   * @returns {number} discount
   */
  #getDiscount = (product) => {
    const withTax = this.#accountStore.showPricesWithTax;
    const savings = product.price_info.getSavings(withTax);
    const precision = this.#currencyStore.activeCurrencyPrecision;
    if (savings) {
      return roundWithPrecision(savings, precision);
    }

    return 0.0;
  };
}

export default Item;
